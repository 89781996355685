* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-size: 16px;
  line-height: 24px;
  min-height: 100vh;
}

.ant-layout {
  overflow: auto;
}

.ant-layout-content {
  padding: 0;
}

@media only screen and (max-width: 992px) {
  td {
    min-width: 200px;
  }

  td:last-child {
    min-width: 150px;
  }
}
