.ck-editor__editable_inline {
  height: 450px;
  overflow: auto;
}
.ck.ck-toolbar {
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ck.ck-content {
  border: 1px solid rgb(204, 206, 209) !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding: 10px 25px !important;
}
